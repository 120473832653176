import PropTypes from "prop-types";
import React from "react";
import {
  TopBar,
  NavContainer,
  IconLogo,
  Nav,
  NavItem,
  NavTeam,
  StyledLogoLink,
  Hamburger,
  HamburgerIcon,
  MobileNavList,
  MobileNavListItem,
  MobileNavListTeam
} from "../styles/HeaderStyles.ts";
import { Helmet } from "react-helmet";
import NavAnimation from '../styles/animation.css';
import MenuIcon from '../images/menu-icon.svg';

import AbsideLogo from "../images/abside_logo_colored.svg";

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.screenResize = this.screenResize.bind(this);

    this.state = {
      mobileNav: false,
      menuOpen: false,
    };
  }

  componentDidMount() {
    const isMobile = window.matchMedia("(maxWidth: 700px) and (orientation: portrait)");

    if (isMobile) {
      this.setState({mobileNav: true});
    } else {
      this.setState({mobileNav: false});
    }

    window.addEventListener('keydown', e => this.handleKeydown(e));
    window.addEventListener('resize', this.screenResize);
  }

  componentWillUnmount() {
    this.setState({menuOpen: false});
    window.removeEventListener('keydown', e => this.handleKeydown(e));
    window.removeEventListener('resize', this.screenResize);
  }

  screenResize = () => {
    const isMobile = window.matchMedia("(max-width: 700px) and (orientation: portrait)");

    if (isMobile) {
      this.setState({mobileNav: true});
    } else {
      this.setState({mobileNav: false})
    }
  }

  toggleMenu = () => this.setState({ menuOpen: !this.state.menuOpen });

  handleResize = () => {
    if (window.innerWidth > 768 && this.state.menuOpen) {
      this.toggleMenu();
    }
  };

  handleKeydown = e => {
    if (!this.state.menuOpen) {
      return;
    }

    if (e.which === 27 || e.key === 'Escape') {
      this.toggleMenu();
    }
  };

  render() {
    const { mobileNav, menuOpen, scrollDirection } = this.state;

    return (
      <TopBar>
        <Helmet>
          <meta charSet="utf-8" />
          <link rel="canonical" href="https://abside-networks.com" />
          <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
          <link rel="icon" href="/favicon.ico" type="image/x-icon" />
          <meta name="description" content="Abside Networks is a technology innovator and leader in spectrally efficient and flexible LTE solutions in non-3GPP frequencies. Since 2013, Abside has developed and delivered multiple generations of LTE communication systems." />
          <meta property="og:title" content="Abside Networks" />
          <meta property="og:type" content="website" />
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no" />
          <meta property="og:url" content="https://abside-networks.com" />
          <meta property="og:description" content="Abside Networks is a technology innovator and leader in spectrally efficient and flexible LTE solutions in non-3GPP frequencies. Since 2013, Abside has developed and delivered multiple generations of LTE communication systems." />
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
          <meta name="msapplication-TileColor" content="#2b5797" />
          <meta name="theme-color" content="#ffffff" />
        </Helmet>
        <NavContainer>
          <StyledLogoLink to="/">
            <IconLogo src={AbsideLogo} />
          </StyledLogoLink>
          <Hamburger onClick={() => this.toggleMenu()}>
            <HamburgerIcon src={MenuIcon} />
          </Hamburger>
          <MobileNavList menuOpen={this.state.menuOpen} className={this.state.menuOpen ? NavAnimation.fadeNavIn : NavAnimation.fadeNavOut}>
            <MobileNavListItem activeClassName="is-active" to="/5G-Products/">5G Products</MobileNavListItem>
            <MobileNavListItem activeClassName="is-active" to="/4G-LTE-Products/">4G LTE Products</MobileNavListItem>
            <MobileNavListItem activeClassName="is-active" to="/HAPS/">HAPS</MobileNavListItem>
            <MobileNavListTeam activeClassName="is-active" href="https://www.linkedin.com/company/abside-networks-inc./people/" target="_blank" rel="noopener noreferrer" width="41">Team</MobileNavListTeam>
            <MobileNavListItem activeClassName="is-active" to="/contact/">Contact</MobileNavListItem>
          </MobileNavList>
          <Nav>
            <NavItem to="/5G-Products/" width="100" activeClassName="active-link">5G Products</NavItem>
            <NavItem to="/4G-LTE-Products/" width="135" activeClassName="active-link">4G LTE Products</NavItem>
            <NavItem to="/HAPS/" width="47" activeClassName="active-link">HAPS</NavItem>
            <NavTeam href="https://www.linkedin.com/company/abside-networks-inc./people/" target="_blank" rel="noopener noreferrer" width="43" activeClassName="active-link">Team</NavTeam>
            <NavItem to="/contact/" width="63" activeClassName="active-link">Contact</NavItem>
          </Nav>
        </NavContainer>
      </TopBar>
    );
  }
}

export default Header;