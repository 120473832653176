import Styled from 'styled-components';
import { Link as GatsbyLink } from "gatsby";
import { Link } from 'gatsby';

export const TopBar = Styled.div`
  display: block;
  position: fixed;
  width: 100%;
  z-index: 1000;
  background-color: #fff;
  height: 60px;
  border-bottom: 1px solid #ccc;
`;

export const NavContainer = Styled.div`
  display: block;
  position: relative;
  max-width: 1240px;
  margin: 0 auto;
`;

export const IconLogo = Styled.img`
  display: block;
  width: 300px;
  padding-top: 10px;
  padding-left: 20px;
  z-index: 100;

  @media (max-width: 900px) {
    width: 200px;
    padding-top: 17px;
  }
`;

export const Nav = Styled.nav`
  display: none;
  float: right;
  width: auto;
  margin: 0;
  padding-top: 18px;
  margin-right: -20px;
  padding-right: 20px;
  opacity: 0;

  @media (min-width: 901px) {
    display: flex;
    opacity: 1;
  }
`;

export const NavItem = Styled(GatsbyLink)`
  display: inline;
  position: relative;
  font-family: 'Helvetica Neue Roman', sans-serif;
  font-size: 18px;
  color: #494956;
  text-decoration: none;
  padding: 0 20px;
    transition: color .3s;

  :hover {
    color: #000;
    transition: color .3s;
  }

  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    right: 21px;
    width: 0;
    bottom: -4px;
    background: #000;
    height: 2px;
    transition-property: width;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }

  &:hover:after,
  &:focus:after,
  &:active:after {
    left: 21px;
    right: auto;
    background: #000;
    width: ${props => props.width + 'px'};
  }

  &.active-link:after {
    left: 21px;
    right: auto;
    background: #000;
    width: ${props => props.width + 'px'};
  }

  &.active-link {
    color: #000;
  }

`;

export const NavTeam = Styled.a`
  display: inline;
  position: relative;
  font-family: 'Helvetica Neue Roman', sans-serif;
  font-size: 18px;
  color: #494956;
  text-decoration: none;
  padding: 0 20px;
    transition: color .3s;

  :hover {
    color: #000;
    transition: color .3s;
    cursor: pointer;
  }

  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    right: 21px;
    width: 0;
    bottom: -4px;
    background: #000;
    height: 2px;
    transition-property: width;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }

  &:hover:after,
  &:focus:after,
  &:active:after {
    left: 21px;
    right: auto;
    background: #000;
    width: ${props => props.width + 'px'};
  }

  &.active-link:after {
    left: 21px;
    right: auto;
    background: #000;
    width: ${props => props.width + 'px'};
  }

  &.active-link {
    color: #000;
  }

`;

export const StyledLogoLink = Styled(GatsbyLink)`
  display: inline-block;
`;

export const Hamburger = Styled.div`
  display: none;
  opacity: 0;
  overflow: visible;
  cursor: grab;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
  height: 60px;
  width: 60px;
  background-color: #fff;
  border-bottom: #ccc 1px solid;

  @media (max-width: 900px) {
    display: block;
    opacity: 1;
  }
`;

export const HamburgerIcon = Styled.img`
  display: block;
  margin-top: 19px;
  margin-right: 19px;
  width: 22px;
  height: 22px;
  float: right;
`;

export const NavLinks = Styled.div`
  align-items: center;
  list-style: none;
  padding-top: 2px;
  opacity: 0;
  display: none;

  @media (min-width: 1079px) {
    display: flex;
    opacity: 1;
  }
`;

export const NavIconList = Styled.a`
  position: relative;
  list-style: none;
  padding: 18px 20px;
  float: left;
  font-family: 'Helvetica Neue Roman', sans-serif;
  font-size: '14px';
  text-decoration: none !important;
  color:#fff;
  transition: color ease-out 0.2s;

  &:hover {
    fill: #fff !important;
    transition: color ease-out 0.2s;
  }
`;

export const MobileNavList = Styled.ol`
  list-style: none;
  position: absolute;
  top: 60px;
  margin: 0;
  text-align: center;
  right: 0;
  padding: 0;
  left: 0;
  background-color: #fff;
  visibility: ${props => props.menuOpen ? 'visible' : 'hidden'};
  opacity: ${props => props.menuOpen ? '1' : '0'};
  transition: opacity ease-in .5s;
`;

export const MobileNavListItem = Styled(Link)`
  position: relative;
  list-style: none;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  width: 100vw;
  float: left;
  left: 0;
  font-family: 'Helvetica Neue Roman', sans-serif;
  font-size: '14px';
  text-decoration: none !important;
  color: #494954;
  transition: color ease-out 0.2s;
  border-bottom: #ccc 1px solid;

  &:hover {
    color: #000;
    transition: color ease-out 0.2s;
  }

  &.is-active {
    color: #000;
    text-decoration: underline !important;
  }
`;

export const MobileNavListTeam = Styled.a`
  position: relative;
  list-style: none;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  width: 100vw;
  float: left;
  left: 0;
  font-family: 'Helvetica Neue Roman', sans-serif;
  font-size: '14px';
  text-decoration: none !important;
  color: #494954;
  transition: color ease-out 0.2s;
  border-bottom: #ccc 1px solid;

  &:hover {
    color: #000;
    transition: color ease-out 0.2s;
  }

  &.is-active {
    color: #000;
    text-decoration: underline !important;
  }
`;